@use 'colors' as *;
@use 'mixin';
@use 'variables';

html {
    font-family: 'Roboto', sans-serif;
    font-size: 95%;
}

h1 {
    @include mixin.font-set(2.31em, $white, bold);
}

h2 {
    @include mixin.font-set(1.4em, $hover-color, 300);

    text-align: left;
}

h3 {
    @include mixin.font-set(1.25em, $title, bold);
}

h1,
h2,
h3 {
    line-height: 1.33em;
    margin-bottom: 0.66em;
}

a {
    @include mixin.linx($hover-color, $title, $title);

    color: $title;
    text-align: center;
    text-decoration: underline;

    &:hover {
        color: $hover-color;
    }
}

p {
    @include mixin.font-set(1em, $title, 300);

    line-height: 1.55;
    margin-bottom: 0.66em;
}

strong {
    font-weight: bold;
}

pre {
    @include mixin.font-set(0.8em, $input-text, 300);

    padding: 0 0 1.6em;
}

code {
    @include mixin.font-set(1.2em, $title, 300);
}

.clear {
    clear: both;
    margin-bottom: 2em;

    a {
        @include mixin.font-set(1em, $title, 300);
        @include mixin.linx($hover-color, none, none);

        display: block;
        text-align: center;
        text-decoration: underline;
    }
}

/*
*
* Homepage
*
*/
.row-box,
.container,
.aldryn-newsblog-pagination {
    margin: 0 variables.$width-large;

    @media (max-width: 73em) {
        margin: 0 variables.$width-middle;
    }

    @media (max-width: 55em) {
        margin: 0 variables.$width-small;
    }

    a {
        color: $title;
        text-decoration: underline;
    }

    strong a {
        font-weight: bold;
    }
}

.row-box {
    margin-top: 4em;
    text-align: center;
}

body.show-body {
    background: $nav-line;
}

#pageContent.hide-content {
    display: none;
}

.container article h1:not(:first-child),
.container article h2:not(:first-child),
.container article h3:not(:first-child) {
    margin-top: 1.66em;
}

.is-homepage .container article h1:not(:first-child),
.is-homepage .container article h2:not(:first-child),
.is-homepage .container article h3:not(:first-child) {
    margin-top: 0;
}

.col-lg-4,
.col-lg-3,
.col-lg-2 {
    padding-bottom: 3em;

    img {
        height: 9em;
    }

    a:hover {
        color: $hover-color;
    }
}

body.show-body .container,
body.show-body footer {
    display: none;
}

body.show-body nav {
    display: block;
}

@media (min-width: 1200px) {
    body .container {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    body .container {
        max-width: 100%;
    }
}

@media (min-width: 576px) {
    body .container {
        max-width: 100%;
    }
}
