@use 'colors' as *;
@use 'mixin';
@use 'variables';

.footer-logo {
    img {
        max-width: 345px;
    }
}

footer {
    border-top: 0.2em solid $nav-line;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2em 0 3em;
    padding: 0 variables.$width-large;
    padding-top: 14px;

    .footer-logo-box {
        padding-top: 6px;

        .footer-logo {
            align-items: center;
            display: flex;

            div {
                @include mixin.font-set(1em, $title, 300);

                padding-right: 0.33em;
            }

            .cznic-logo {
                height: 1.2em;
            }

            .logo-csirt {
                height: 1.8em;
            }
        }
    }
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    footer {
        padding: 10px variables.$width-middle;
    }
}

/*
*
* media-max: 55em
*
*/
@media (max-width: 55em) {
    footer {
        flex-direction: column;
        margin: 1em 0 0;
        padding: 10px variables.$width-small;

        .footer-logo-box {
            @include mixin.flex-gsw(0, 0, auto);

            margin: 1em 0 3em;

            .footer-logo {
                margin: 1em auto;

                div {
                    @include mixin.flex-gsw(0, 0, 210px);
                }
            }
        }
    }
}

/*
*
* media-max: 24em
*
*/
@media (max-width: 55em) {
    .footer-logo {
        img {
            max-width: 270px;
        }
    }

    footer {
        .footer-logo-box {
            .footer-logo {
                div {
                    @include mixin.flex-gsw(0, 0, 160px);
                }
            }
        }
    }
}
