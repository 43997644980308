@use 'colors' as *;
@use 'mixin';
@use 'variables';

row-box-bg {
    background: $main;
    color: $white;
    padding: 25px variables.$width-large;

    @media (max-width: 73em) {
        padding: 25px variables.$width-middle;
    }

    @media (max-width: 55em) {
        padding: 25px variables.$width-small;
    }

    .row {
        justify-content: space-between;
        margin-left: 0;

        @media (max-width: 55em) {
            margin: 0;
        }
    }

    .col-lg-8 {
        padding: 70px 30px 90px 0;

        p {
            @include mixin.font-set(1.5em, $white, 300);
        }

        .btn {
            @include mixin.font-set(1.5em, $white, bold);

            border: 0.13333em solid $white;
            border-radius: 0.5em;
            display: inline-block;
            margin-top: 2em;
            padding: 0.5em 1.5em;
            text-transform: uppercase;
            transition: all 0.1 linear;

            &:hover {
                background: $white;
                color: $main;
            }
        }
    }

    .col-lg-4 {
        @include mixin.flex-gsw(0, 0, 364px);

        @media (max-width: 62em) {
            @include mixin.flex-gsw(1, 0, auto);

            margin: 0 0 2em;
            padding: 0;
        }

        p {
            @include mixin.font-set(1em, $white, 300);
        }

        > a {
            @include mixin.font-set(1em, $border, 300);

            display: block;
            line-height: 1.5;
            text-align: right;
            text-decoration: underline;

            &::after {
                content: ' »';
            }
        }

        .border-text {
            border: 0.12em solid $border;
            padding: 2em;

            .txt-muted {
                @include mixin.font-set(1.2em, $border, 300);

                display: inline-block;
                line-height: 1.33em;
                margin-bottom: 0.66em;
                text-decoration: underline;
            }

            p,
            .news-title-small {
                @include mixin.font-set(1em, $border, 300);
            }

            .news-title-small {
                text-decoration: underline;
            }
        }
    }
}
