@use 'colors' as *;

.search-results {
    .results-list {
        .highlighted {
            color: $main;
        }
    }
}

.pagenav ul {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    li {
        float: left;
        list-style-image: none;
        margin-right: 0.6em;

        span {
            background-color: $debug;
            line-height: 1.5;
        }

        a,
        span {
            border: solid $border 1px;
            display: block;
            padding: 0.2em 1em;
        }

        a:hover {
            background-color: $debug;
        }
    }
}
