$debug: #ccc;
$info: #cab999;
$warning: #f6d128;
$success: #32c518;
$error: #bd122e;
$nav-line: #dadada;
$hover-color: #015184;
$input: #a5a5a4;
$main: #f5002d;
$black: #000;
$white: #fff;
$title: #333;
$input-text: #666;
$border: #dadada;
$border2: #808080;
$bg-table: #f4f4f4;
