@use 'colors' as *;
@use 'mixin';

/*
*
* text-page
*
*/
.container {
    padding: 2em 0;
    width: auto;

    h1 {
        @include mixin.font-set(1.8em, $hover-color, 300);
    }

    h2 {
        @include mixin.font-set(1.33em, $hover-color, 300);

        padding-top: 1em;
    }

    h3 {
        @include mixin.font-set(1.2em, $hover-color, 300);

        padding-top: 1em;
    }

    h4 {
        @include mixin.font-set(1.4em, $title, 300);

        line-height: 1.5;
        padding: 1em 0 3em;
    }

    a {
        @include mixin.linx($hover-color, none, none);

        color: $title;
        text-decoration: underline;
    }

    p {
        img {
            max-width: 840px;

            @media (max-width: 73em) {
                max-width: 100%;
            }
        }
    }

    ul {
        margin: 0 0 2em 1.5em;

        li {
            @include mixin.font-set(1em, $title, 300);

            line-height: 1.5;
            list-style-image: url('../img/arrow-list.png');
            margin-bottom: 0.66em;

            p {
                margin-bottom: 1em;
            }

            a,
            code {
                line-height: 1.5;
            }

            code {
                @media (max-width: 55em) {
                    font-size: 0.8em;
                }
            }
        }
    }

    .border-text,
    .news-version {
        border: 0.2em solid $border;
        padding: 0 2em 2em;

        .add-underline {
            border-bottom: 0.1em solid $border;
            display: block;
            padding-bottom: 1.2em;
            text-align: left;
        }

        pre {
            padding: 0 0 1em;
        }

        p:last-of-type .add-underline {
            margin-bottom: 3em;
        }
    }

    .news-version {
        border: 0;
        padding: 0;

        p:last-of-type .add-underline {
            border: 0;
        }
    }

    .col-lg-9 {
        padding-left: 3em;
    }

    table {
        @include mixin.font-set(1em, $title, 300);

        border: 1px solid $debug;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 1em 0;

        th {
            background-color: $bg-table;
            border-bottom: 1px solid $debug;
            border-left: 1px dotted $debug;
            font-weight: bold;
            line-height: 1.6;
            padding: 0.2em 6px;
            vertical-align: top;
        }

        td {
            border-bottom: 1px solid $debug;
            border-left: 1px dotted $debug;
            line-height: 1.6;
            padding: 0.2em 6px;
            vertical-align: top;
        }
    }

    .row.column-reverse {
        @media (max-width: 62em) {
            flex-direction: column-reverse;

            .col-lg-9 {
                padding-left: 1em;
            }
        }
    }
}
