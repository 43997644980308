.nav-social {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    float: right;
    height: 100%;
    margin: 0;
    min-height: 45px;
    padding: 0;

    li {
        display: inline-block;
        margin: auto auto auto 14px;

        a {
            text-decoration: none;

            img {
                height: 26px;
                opacity: 0.4;
                transition: 0.1s;

                &:hover {
                    opacity: 1;
                }
            }

            svg {
                height: 28px;
                opacity: 0.4;
                transform: translate(2px, 1.5px);
                transition: 0.1s;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    @media (max-width: 61em) {
        .lang {
            display: block;
            width: 100%;

            a {
                display: block;
                padding: 10px 20px 5px;
                text-align: left;
                width: 100%;
            }
        }
    }
}
