@use 'colors' as *;
@use 'mixin';
@use 'variables';

.menu-icon {
    display: none;
}

#closeMenu {
    display: none;
}

.page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 1em variables.$width-large;

    a {
        text-align: left;
    }

    .logo {
        height: 5.75em;
    }

    .search-form {
        display: flex;

        .search-input {
            appearance: none;
            border: 1px solid $input;
            border-right: 0;
            color: $input-text;
            padding: 9px 5px 9px 10px;
            transform: all 0.3s linear;
            width: 165px;
        }

        #search-button {
            appearance: none;
            background: $white;
            border: 1px solid $input;
            border-left: 0;
            cursor: pointer;
            padding-top: 5px;
            width: 35px;

            svg {
                fill: $input;
            }

            &:hover {
                background: $input;
            }

            &:hover svg {
                fill: $white;
            }
        }
    }
}

nav {
    border-bottom: 0.2em solid $nav-line;
    border-top: 0.2em solid $nav-line;
    padding: 0 variables.$width-large;

    #page-navigation {
        display: inline-block;
        margin-bottom: 0;
        padding: 0;

        .active {
            box-shadow: 0 0.2em 0 0 $main;
        }

        li {
            display: inline-block;
            line-height: 3;
            margin-right: 1em;

            a {
                @include mixin.font-set(1em, $title, bold);

                &:hover {
                    color: $main;
                }
            }
        }
    }
}

ul#page-navigation > li.active a {
    color: $main;
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    nav {
        padding: 0 variables.$width-middle;
    }

    .page-header {
        margin: 1em variables.$width-middle;
    }
}

/*
*
* media-max: 61em
*
*/
@media (max-width: 61em) {
    .page-header {
        margin: variables.$width-small;

        .search-form {
            .search-input {
                font-size: 1.2em;
                width: calc(100% - 35px);
            }
        }
    }

    nav {
        border: 0;
        display: none;
        margin: 1em;
        padding: 0;

        .nav-social {
            float: none;

            li {
                display: inline-block;
                margin: 7.5px 0.7em 0 0;
                padding: 0;
            }

            li:first-of-type {
                padding: 0 0 0 1em;
            }
        }

        #page-navigation {
            display: block;

            .active {
                box-shadow: -0.2em 0 0 0 $main;
            }

            li {
                display: block;
                line-height: 3;
                margin-right: 1em;
                padding: 0 0 0 1em;

                a {
                    @include mixin.font-set(1em, $title, bold);
                }
            }
        }
    }

    #openMenu.hide-icon {
        display: none;
    }

    #closeMenu.show-icon {
        display: block;
    }

    .menu-icon {
        display: block;
    }
}

nav {
    @media (min-width: 61em) {
        #page-navigation {
            max-width: 55%;
        }

        .nav-social {
            max-width: 45%;
        }
    }

    @media (min-width: 81em) {
        #page-navigation {
            max-width: 60%;
        }

        .nav-social {
            max-width: 40%;
        }
    }
}
