@use 'colors' as *;
@use 'mixin';

$side-l: 33.33%;
$side-r: 65.96%;

.base-form {
    max-width: 635px;

    h2 {
        max-width: 600px;
    }

    .help {
        clear: both;
        font-size: 0.9em;
        margin: 0 0 0 $side-l;
        max-width: 395px;
        padding-left: 0.4em;
        transform: translate(0, 3px);
    }

    .help-text {
        font-size: 0.8em;
        margin-left: $side-l;
        width: $side-r;

        @media (max-width: 55em) {
            margin-left: 0;
            width: 100%;
        }
    }

    button[type='submit'] {
        background: $white;
        border: 2px outset $border2;
        border-radius: 5px;
        display: block;
        float: right;
        margin: 1.5em 0 0 35%;
        padding: 0.2em 2.2em;
        width: auto;
    }

    .errormessages {
        margin: 0 0 0 $side-l;

        li {
            color: $error;
            font-weight: 900;
            list-style-image: none;
            padding-left: 0.4em;
        }
    }

    label {
        color: $title;
        display: inline-block;
        line-height: 2;
        margin: 0.2em auto 0.41em;
        padding: 0 1em 0 0;
        position: relative;
        text-align: right;
        width: $side-l;

        abbr {
            @include mixin.font-set(1.1em, $error, bolder);

            position: absolute;
            right: 3px;
            top: -2px;
        }

        &::after {
            content: ':';
            position: absolute;
            right: 11px;
        }
    }

    p {
        abbr {
            @include mixin.font-set(1.1em, $error, bolder);

            position: absolute;
            right: 3px;
            top: 12px;
        }

        &::after {
            content: ':';
            position: absolute;
            right: 11px;
        }
    }

    input,
    textarea {
        border: 1px solid $debug;
        border-radius: 0;
        float: right;
        padding: 0.2em;
        transform: translate(0, 3px);
        width: $side-r;
    }

    select {
        background: $white;
        border: 1px solid $debug;
        border-radius: 0;
        float: right;
        padding: 0.1em;
        transform: translate(0, 3px);
        width: $side-r;

        option {
            line-height: 1.55em;
        }
    }

    .text-danger {
        @include mixin.font-set(1.5em, $white, bolder);

        background: $error;
        display: block;
        float: right;
        margin: 0.4em 0;
        padding: 0.2em;
        text-align: center;
        width: 100%;
    }

    .booleanfield {
        align-items: baseline;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-left: $side-l;
        width: 100%;

        @media (max-width: 55em) {
            margin-left: 0;
        }

        label {
            margin: 0;
            width: auto;

            &::after {
                content: '';
            }
        }

        input {
            margin-right: 5px;
            width: auto;
        }

        .errormessages {
            margin: 0;
            width: 100%;
        }
    }

    .checkbox,
    ul[id*='id_'] {
        margin: 1em 0;

        p {
            display: inline-block;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 0;
            padding: 0.8em 1em 0 0;
            position: relative;
            text-align: right;
            width: $side-l;

            @media (max-width: 55em) {
                text-align: left;
                width: auto;
            }

            &::after {
                content: ':';
                position: absolute;
                right: 11px;
            }
        }

        label {
            float: right;
            font-weight: 300;
            margin: 0.2em 0;
            text-align: left;
            width: $side-r;

            @media (max-width: 55em) {
                width: 100%;
            }

            input {
                float: none;
                width: auto;
            }

            &::after {
                content: '';
            }
        }

        &::after {
            clear: both;
            content: '';
            display: block;
        }

        .text-danger {
            background-color: transparent;
            color: $error;
            float: right;
            font-size: 1em;
            text-align: inherit;
            width: $side-r;

            @media (max-width: 55em) {
                float: none;
            }
        }
    }

    ul[id*='id_'] {
        float: right;
        margin: 0;
        width: $side-r;

        &::after {
            clear: both;
            content: '';
            display: block;
        }

        li {
            list-style-image: none;
            margin-top: 0;
        }

        label {
            width: 100%;
        }
    }

    .captcha {
        transform: translate(1px, 15px);
    }

    .clear-both {
        &::after {
            clear: both;
            content: '';
            display: block;
        }
    }

    .text-danger.aldryn-forms.aldryn-forms-submit-msg {
        background-color: $white;
    }

    input[type='file'] {
        border: 0;
    }

    input[type=file].drag-and-drop {
        background-color: $border;
        background-image: url('../img/upload-file.svg');
        background-position: 88% center;
        background-repeat: no-repeat;
        border: 2px dashed #ccc;
        border-radius: 0.2em;
        margin-bottom: 1em;
        padding: 2em;
        width: 100%;
    }

    .upload-file-frame {
        width: $side-r;

        @media (max-width: 55em) {
            width: 100%;
        }

        .fail-upload {
            color: $hover-color;
            text-decoration: line-through;

            img {
                margin-right: 0.4em;
                position: relative;
                top: 4px;
            }
        }
    }

    img {
        margin: 0 0 0.2em;
    }

    input[id*='captcha'] {
        margin-left: $side-l;

        @media (max-width: 55em) {
            margin-left: 0;
        }
    }

    input[name*='file'] {
        padding-left: 0;
    }

    .captcha {
        transform: translate(0, 1em);

        @media (max-width: 55em) {
            transform: translate(0, 0);
        }
    }

    w-auto {
        width: auto;
    }

    label + input[type=file].drag-and-drop {
        width: $side-r;

        @media (max-width: 55em) {
            width: 100%;
        }
    }
}

@media (max-width: 55em) {
    .base-form {
        .help,
        .errormessages {
            margin: 0;
            max-width: 100%;
        }

        label {
            margin-bottom: 0;
            padding: 0;
            text-align: left;
            width: 100%;

            abbr {
                position: static;
            }

            &::after {
                content: ':';
                position: relative;
                right: 14px;
            }
        }

        .captcha,
        .help {
            display: block;
            transform: translate(0, 0);
        }

        input,
        textarea,
        select {
            float: none;
            margin: 0 0 6px;
            width: 100%;
        }

        ul[id*='id_'] {
            float: none;
            width: 100%;
        }
    }
}
